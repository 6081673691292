export const getContent = (item) => {
  let msg = item.messageList[0];
// console.log("msg",msg);
// console.log("SON.parse(msg.content)",JSON.parse(msg.content));
  switch (msg.messageType) {
    case "0":
      return msg.content;
    case "13":
      return msg.content;
    case "1":
      return "[图片]";
    case "2": {
      let order = JSON.parse(msg.content);
      console.log(order);
      return `${order.intentionRegion ? order.intentionRegion : order.areaName}${
        order?.serviceType == 1 ? "全程托管" : "环节托管}"
      }${order?.serviceArea}亩${order?.serviceScope}]`;

    }
    case "3": {
      let order = JSON.parse(msg.content);
      return `${order?.address}${
        order?.serviceType == 1 ? "全程托管" : "环节托管}"
      }${order?.serviceArea}亩${order?.serviceScope}]`;

    }
    // case "4": {
    //   let order = JSON.parse(msg.content);
    //   return `[${order?.areaName}${
    //     order?.serviceType == 1 ? "全程托管" : "环节托管}"
    //   }${order?.landArea}亩${order?.cropCode}]`;

    // }
    // case "5": {
    //   let order = JSON.parse(msg.content);
    //   return  `[${order?.address}${
    //     order?.serviceType == 1 ? "全程托管" : "环节托管"} ${
    //     order?.serviceArea}亩${order?.serviceScope}]`;
    // }
    case "6": {
      let order = JSON.parse(msg.content);
      return `${order.sourceFileName}`;
    }
    case "7": {
      // let order = JSON.parse(msg.content);
      return `[请求交换联系方式]`;
    }
    case "8": {
      let order = JSON.parse(msg.content);
      return `${order.areaName}${
        order.serviceType == 1 ? "全程托管" : "环节托管}"
      }${order.serviceArea}亩${order.serviceScope}]`;
    }
    case "9": {
      let order = JSON.parse(msg.content);
      console.log("9",order);
      return `${order.xmmc}`;
    }
    case "10": {
      let order = JSON.parse(msg.content);
      console.log("10",order);
      return `${order.xmmc}`;
    }
    case "11": {
      // let order = JSON.parse(msg.content);
      return `[申请查看产权证书]`;
    }
    // seasonYield
  }
}
